import './js/sentry';
import * as Turbo from '@hotwired/turbo';
import { registerSvelteControllerComponents } from '@symfony/ux-svelte';
import 'core-js/actual/structured-clone';
import('flatpickr/dist/themes/light.css');

Turbo.session.drive = false;

import './css/main.scss';
import '@splidejs/splide/css';

import BannerCarouselController from './controllers/banner_carousel_controller.js';
import CarouselController from './controllers/carousel_controller.js';
import CarouselMosaicController from './controllers/carousel_mosaic_controller.js';
import ModalController from './controllers/modal_controller.js';
import MenuDesktopController from './controllers/menu_desktop_controller.js';
import MenuMobileController from './controllers/menu_mobile_controller.js';
import NewsletterController from './controllers/newsletter_controller.js';
import ObfuscatorController from './controllers/obfuscator_controller.js';
import SeeMoreController from './controllers/see_more_controller.js';

// start the Stimulus application
import { startStimulusApp } from '@symfony/stimulus-bridge';

// Registers Stimulus controllers from controllers.json and in the controllers/ directory
export const app = startStimulusApp();

app.register('banner-carousel', BannerCarouselController);
app.register('carousel', CarouselController);
app.register('carousel-mosaic', CarouselMosaicController);
app.register('menu-desktop', MenuDesktopController);
app.register('menu-mobile', MenuMobileController);
app.register('modal', ModalController);
app.register('newsletter', NewsletterController);
app.register('obfuscator', ObfuscatorController);
app.register('see-more', SeeMoreController);

// Import Svelte components individually
import HomeSearchController from './svelte/saga/controllers/HomeSearchController.svelte';
import SearchBarController from './svelte/saga/controllers/SearchBarController.svelte';

// Create a custom context function that behaves like require.context for registerSvelteControllerComponents to work
const customContext = function (key) {
    return customContext._components[key];
};

customContext._components = {
    './HomeSearchController.svelte': { default: HomeSearchController },
    './SearchBarController.svelte': { default: SearchBarController },
};

customContext.keys = function () {
    return Object.keys(this._components);
};

// Register our Svelte components
registerSvelteControllerComponents(customContext);
